(function comp(name) {
    const selector = $(`.${name}`);

    if (selector.length) {
        let carousel = selector.find("[data-visual-slider]");
        let caption = selector.find('[data-visual-caption]');
        let autoplay = carousel.data("visual-slider");

        $.lib_flickity(function(){
            $.importScript(cdnjs.flickity_sync, function() {
                if(caption.children().length > 1) {
                    caption.flickity({
                        contain: true,
                        pageDots: false,
                        prevNextButtons: false,
                        pauseAutoPlayOnHover: false
                    });
                }

                if (carousel.length) {
                    carousel.flickity({
                        imagesLoaded: false,
                        percentPosition: false,
                        setGallerySize: false,
                        autoPlay: autoplay,
                        pageDots: true,
                        wrapAround: false,
                        contain: true,
                        sync: '[data-visual-caption]',
                        prevNextButtons: true
                    });
                }

                let carousel_img = carousel.find('.elm_item .elm_item_image'),
                    docStyle = document.documentElement.style,
                    transformProp = typeof docStyle.transform === 'string' ? 'transform' : 'WebkitTransform';

                let flkty = carousel.data('flickity');

                carousel.on('scroll.flickity', function () {
                    flkty.slides.forEach(function (slide, i) {
                        let img = carousel_img[i],
                            x = (slide.target + flkty.x) * -1/2;

                        img.style[transformProp] = 'translateX(' + x + 'px)';
                    });
                });
            });

            selector.on("click", "[data-scroll-next]", function(){
                $('html, body').animate({
                    scrollTop: selector.next().offset().top - 60
                }, 1000);
            });
        });

        window.lui[name] = comp;
    }
})("comp_base_visual");