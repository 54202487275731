var reInitGCaptcha;

(function($) {
    fn_nl_lib_anchor();
    
    reInitGCaptcha = function() {
        $.getScript("https://www.google.com/recaptcha/api.js?render=6LcstoAUAAAAAJ6iFSjVE3Pz3Y_teRq6ijBnTs5N").done(function () {
            grecaptcha.ready(function () {
                grecaptcha.execute('6LcstoAUAAAAAJ6iFSjVE3Pz3Y_teRq6ijBnTs5N', {action: 'form'})
                    .then(function (token) {
                        $('#g-token').val(token);
                    });
            });
        });
    };
    
    if ($('form[data-recaptcha]').length) {
        reInitGCaptcha();
    }
    
    doc.on('submit', 'form[data-component-ajax]', function (e) {
        e.preventDefault();
        
        var submitButton = $(this).find('button[type="submit"]');
        if(submitButton.length) {
            submitButton.attr('disabled','disabled');
            submitButton.addClass('mod--loading');
        }
        
        let frm = $(this),
            formData = new FormData($(this)[0]);

        $.ajax({
            method: frm.attr('method'),
            url: frm.attr('action'),
            data: formData,
            processData: false,
            contentType: false,
            dataType: "json",
            success: function (payload) {
                fn_ajaxHandler(payload, function(){
                    if ($('form[data-recaptcha]').length) {
                        reInitGCaptcha();
                    }

                    if(submitButton.length) {
                        setTimeout(function() {
                            submitButton.removeAttr('disabled');
                            submitButton.removeClass('mod--loading');
                        },3000);
                    }
                });
            }
        });
    });
    
    doc.on('click','a[data-component-ajax]',function(e) {
        $('[data-component-ajax]').removeClass('state--active');
        $(this).closest('[data-component-ajax]').addClass('state--active');
        e.preventDefault();
        let url = $(this).attr('data-href');
        if(!url || !url.length) {
            url = $(this).attr('href');
        }

        if(!url || !url.length) {
            console.log('no href attribute for ajax component link');
            return;
        }

        $.ajax({
            url: url,
            method: 'post',
            dataType: 'json'
        }).done(function (payload) {
            fn_ajaxHandler(payload,function() {
                ScrollReveal().sync();
            });
        });
    });

    $(".lib--parallax").find("img:not(.lazyload)").parent().parallax();

    doc.on('lazybeforeunveil', '.lazyload', function(e){
        if ($(e.target).parent().hasClass("lib--parallax")) {
            $(e.target).parent().parallax();
        }
    });

    $.libInit(".part_ui_link, .part_ui_checkbox, .part_ui_radio", "lib_ripple", function(selector) {
        $(selector).nl_lib_ripple();
    });

    $.libInit(".part_gallery, .part_ui_radio", "lib_gallery", function(selector) {
        $.importStyle(cdnjs.fancybox_css);
        $.importScript(cdnjs.fancybox, function(){
            $(selector).fancybox({
                selector: ".lib--fancybox-item",
                transitionEffect: "slide",
                buttons: [
                    "close"
                ]
            });
        });
    });

    (function fn_pictureFill() {
        let picture = document.createElement('picture'),
            strObj = '' + picture;

        if(strObj.indexOf("HTMLPictureElement") === -1 && !html.hasClass("ie"))
        {
            $.importScript(cdnjs.picturefill);
        }
    })();

    if ($(".part_cookie_consent").length || $(".part_form_cookies").length) {
        $.importScript(cdnjs.cookieconsent);
    }
})(jQuery);