(function($) {
    let timeout;

    doc.on("click",".part_ui_dropdown [aria-expanded]",function(e){
        let el = $(this);
        let fn = el.closest('.part_ui_dropdown');
        e.preventDefault();
        e.stopPropagation();
        clearTimeout(timeout);
        
        $.fn.close = function() {
            let elm = $(this);
            elm.removeClass("state--toggled").next("[aria-hidden]").attr("aria-hidden","true");
            timeout = setTimeout(function(){
                elm.attr("aria-expanded","false");
            },300);
        };

        $.fn.open = function() {
            $(this).addClass("state--toggled").attr("aria-expanded","true").next("[aria-hidden]").removeAttr("aria-hidden");
        };

        html.one("click", function() {
            el.close();
        });
        if (el.hasClass("state--toggled")) {
            el.close();
        } else {
            el.open();
        }
        fn.not(el.closest(fn)).find('[aria-expanded="true"]').close();
    });
})(jQuery);