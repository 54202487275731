(function comp(name) {
    const selector = $(`.${name}`);

    if (selector.length) {
        let slider = selector.find("[data-slider]");

        if (slider.length) {
            $.lib_flickity(function(){

                slider.flickity({
                    groupCells: '25%',
                    cellAlign: 'left',
                    contain: true,
                    pageDots: false,
                    wrapAround: false,
                    autoPlay: 6000,
                    pauseAutoPlayOnHover: true
                });
            });
        }

        window.lui[name] = comp;
    }
})("comp_base_brands");