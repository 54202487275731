(function comp(name) {
    const selector = $(`.${name}`);

    if (selector.length) {
        let map = selector.find("[data-google-map]");

        $.importScript(cdnjs.googlemap.replace("APIKEY", map.data("google-map")), function(){
            map.nl_google_map("init");
        });

        window.lui[name] = comp;
    }
})("comp_contact_map");