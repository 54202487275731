$.fn.nl_google_map = function(event,data) {
    return this.each(function() {
        var $this = $(this);
        if (!$this.length) {
            return false;
        }
        if (event === 'init') {
            nl_google_map_init();
        }

        function nl_google_map_init() {
            var $zoom = $this.data("zoom"),
                $coords = $this.data('coords').split(/[\s,]+/),
                $markers_temp = [],
                $map_options = {
                    zoom: $zoom,
                    center: {
                        lat: parseFloat($coords[0]), lng: parseFloat($coords[1])
                    }
                };

            var $map = new google.maps.Map($this[0], $map_options);

            var $bounds = new google.maps.LatLngBounds();

            if ($this.filter("[data-marker]").length) {
                var $main_marker_position = new google.maps.LatLng(parseFloat($coords[0]), parseFloat($coords[1]));
                var $main_marker_image = {
                    url: $this.data("marker"),
                    anchor: new google.maps.Point(30, 60),
                    scaledSize: new google.maps.Size(46, 63)
                };

                var $main_marker = new google.maps.Marker({
                    position: $main_marker_position,
                    map: $map,
                    zIndex: 9999,
                    icon: $main_marker_image
                });

                if ($this.data("marker-url").length > 0) {
                    google.maps.event.addListener($main_marker, 'click', function(){
                        window.location.href = $this.data("marker-url")
                    });
                }

                $markers_temp.push($main_marker);
                $bounds.extend($main_marker_position);
            }
        }
    });
};